import '@/styles/index.css';
import { Toaster } from '@/components/ui/toaster';
import '@/components/framer/styles.css';
import 'baby-i-am-faded/styles.css';
import { SessionProvider, signIn, useSession } from 'next-auth/react';
import { ThemeProvider } from 'next-themes';
import NextNprogress from 'nextjs-progressbar';
import { env, plansConfig } from '@/env';
import { AppError } from '@/lib/errors';
import { BeskarProvider } from 'beskar/src/BeskarProvider';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { Toaster as HotToaster } from 'react-hot-toast';
import { NextUIProvider } from '@nextui-org/react';
import { conversionSignUp, conversionSubscribeTTS } from '@/lib/conversions';
import { getSubscriptions } from '@/pages/api/functions';
import { notifyError } from '@/lib/sentry';
function MyApp({
  Component,
  pageProps
}) {
  const router = useRouter();
  const isDashboard = router.asPath.startsWith('/board');

  // const updateUpgradeModal = useUpdateAtom(atomUpgradeModal)

  useEffect(() => {
    if (env.NEXT_PUBLIC_ENV !== 'production') {
      window.loginForTests = async ({
        name,
        email
      }) => {
        if (!email) {
          throw new AppError('Email is required for test provider');
        }
        // const providers = await getProviders()
        // console.log({ providers })
        return await signIn('test-provider', {
          name,
          email,
          callbackUrl: '/empty',
          redirect: false
        });
      };
    }
  }, []);
  // const forcedTheme = !isDashboard ? 'light' : undefined

  return <SessionProvider session={pageProps?.session}>
            <SignupConversionComponent />
            <NextUIProvider>
                <Script async src='https://cdn.splitbee.io/sb.js'></Script>

                {/* <Script
                 src='https://assets.lemonsqueezy.com/lemon.js'
                 strategy='lazyOnload'
                 onLoad={() => {
                    try {
                        window.createLemonSqueezy()
                        window.LemonSqueezy.Setup({
                            eventHandler: (event) => {
                                console.log(event)
                                if (event?.event === 'Checkout.Success') {
                                    console.log('Checkout.Success')
                                    mutate('credits')
                                }
                                // Do whatever you want with this event data
                            },
                        })
                    } catch (error) {
                        notifyError(error, 'lemon')
                    }
                 }}
                 /> */}
                <Script src='https://analytics.ahrefs.com/analytics.js' data-key='Of/qKxrDCtNyy5ZNsjmkPw' async></Script>
                <Script async src='https://www.googletagmanager.com/gtag/js?id=AW-11353691377'></Script>
                <Script id='gtag'>
                    {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11353691377');`}
                </Script>
                {/* <CrispScript /> */}
                <BeskarProvider>
                    <HotToaster containerStyle={{
          zIndex: 10000
        }} position='top-center' />

                    <ThemeProvider defaultTheme='dark' enableSystem={false} attribute='class'
        // forcedTheme={forcedTheme}
        >
                        <Toaster />

                        <InjectCrispEmail />
                        <NextNprogress color='#29D' startPosition={0.3} stopDelayMs={200} height={4} options={{
            showSpinner: false
          }} showOnShallow={true} />

                        <Component {...pageProps} />
                    </ThemeProvider>
                </BeskarProvider>
            </NextUIProvider>
        </SessionProvider>;
}
export default MyApp;
function CrispScript() {
  const {
    data: session
  } = useSession();
  if (!session) {
    return null;
  }
  return <Script id='crisp' type='text/javascript'>{`window.$crisp=[];window.CRISP_WEBSITE_ID="794809b8-5948-4269-a3ba-33ec690dabd7";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}</Script>;
}
function InjectCrispEmail() {
  const {
    data: session
  } = useSession();
  useEffect(() => {
    const email = session?.user?.email;
    if (!email) {
      return;
    }
    if (!window.$crisp) {
      console.warn(`Cannot set Crisp email, Crisp not loaded`);
      return;
    }
    window.$crisp.push(['set', 'user:email', email]);
  }, [session]);
  const router = useRouter();
  // set user segments
  // useEffect(() => {
  //     if (!orgId || !session) {
  //         return
  //     }
  //     setTimeout(async () => {
  //         try {
  //             const { subscription, freeTrial } = await getSubscription({
  //                 orgId,
  //             })
  //             if (subscription && !freeTrial) {
  //                 console.log(`Setting Crisp segment to 'paying-user'`)
  //                 $crisp.push(['set', 'session:segments', [['paying-user']]])
  //                 let value = subscription?.unit_amount || ''
  //                 $crisp.push([
  //                     'set',
  //                     'session:data',
  //                     [[['user-plan', '$' + value]]],
  //                 ])
  //             }
  //         } catch (error) {
  //             console.error(error)
  //         }
  //     }, 1000)
  // }, [session, orgId])
  return null;
}
function SignupConversionComponent() {
  useSignupConversion();
  useSubscribeConversion();
  // useSignupReason({})
  return null;
}
function useSignupConversion() {
  const {
    data: session
  } = useSession();
  const router = useRouter();
  const signupConversionLocalStorageKey = 'signupConversionLocalStorageKey';
  useEffect(() => {
    if (!session) {
      return;
    }
    const signupReason = router.query.signupReason;
    const sentSignup = localStorage.getItem(signupConversionLocalStorageKey);
    if (!sentSignup && session?.jwt?.isNewUser) {
      const email = session?.jwt?.email;
      conversionSignUp({
        signupReason,
        email
      });
      localStorage.setItem(signupConversionLocalStorageKey, 'true');
    }
  }, [session]);
  return null;
}
function useSubscribeConversion() {
  const {
    data: session
  } = useSession();
  const router = useRouter();
  const signupConversionLocalStorageKey = 'ttsSubscribeConversionLocalStorageKey';
  useEffect(() => {
    if (!session) {
      return;
    }
    Promise.resolve().then(async () => {
      const subs = await getSubscriptions({});
      for (let sub of subs) {
        const k = signupConversionLocalStorageKey + sub.subscriptionId;
        const sent = localStorage.getItem(k);
        const veryNew = new Date(sub.createdAt).getTime() > Date.now() - 1000 * 60 * 60 * 24;
        if (sent || !veryNew) {
          return;
        }
        const email = session?.jwt?.email;
        const config = plansConfig.find(x => String(x.variantId) === String(sub.variantId));
        if (!config) {
          console.error(`Cannot find config for variantId ${sub.variantId}`);
          continue;
        }
        const value = config.usd;
        const currency = 'USD';
        conversionSubscribeTTS({
          currency,
          value,
          email
        });
        localStorage.setItem(k, 'true');
      }
    }).catch(e => {
      notifyError(e, 'useSubscribeConversion');
    });
  }, [session]);
  return null;
}