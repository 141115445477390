import { env } from '@/env'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { AppError, KnownError } from './errors'
import { Sema } from 'async-sema/src'

export function splitTextInParts(
    text: string,
    maxLen = 300,
    separators = ['!', '?', '.', ';', ',', ' '],
): string[] {
    text = removeNonSpoken(text)
    let parts = _splitTextInParts(text, maxLen, separators)
    return parts
}

// splits the audio into parts of maxLen, it prefers to split using the first separator, then the second, etc
export function _splitTextInParts(
    text: string,
    maxLen: number,
    separators: string[],
) {
    if (!text) {
        return []
    }
    if (text.length <= maxLen) {
        return [text.trim()]
    }
    let remaining = text.slice()
    for (let sep of separators) {
        while (remaining.length > maxLen) {
            let i = remaining.lastIndexOf(sep, maxLen - 1)
            if (i < 0) {
                break
            }
            let part = remaining.slice(0, i)
            remaining = remaining.slice(i + 1)
            if (part.length + 1 > maxLen) {
                continue
            }
            return [
                part.trim() + sep,
                ..._splitTextInParts(remaining, maxLen, separators).filter(
                    Boolean,
                ),
            ]
        }
    }
    throw new AppError(
        `Could not split into max ${maxLen} text: '${text}', remaining: '${remaining}'`,
    )
}

export function removeNonSpoken(text: string) {
    text = text.replace(/(.)\.(\s|\n)/g, '$1!$2')
    text = text.replace(/[^\x00-\x7F]/g, '')
    text = text.replace(/(\r\n|\n|\r)/gm, '')
    // merge together multiple spaces
    text = text.replace(/\s+/g, ' ')

    return text
}

export async function concatAudioUrls(audioSources: string[]) {
    // creates a new audio block with the merged audio clips
    let blobs = await Promise.all(
        audioSources.map(async (url) => {
            let r = await fetch(url)
            let blob = await r.blob()
            return blob
        }),
    )
    let blob = new Blob(blobs, { type: 'audio/mp3' })
    return blob
}

export function createBuyLink({ email, orgId }) {
    let productId =
        env.NEXT_PUBLIC_ENV === 'production'
            ? '5e71789f-c727-4f2e-81f4-dfab3daa5c89'
            : 'a7f8fc54-1979-49ce-bb73-3bbfe57e1c20'
    let url = new URL(
        `https://tiktoktts.lemonsqueezy.com/checkout/buy/${productId}`,
    )
    if (orgId) {
        url.searchParams.set('checkout[custom][orgId]', orgId)
    }
    url.searchParams.set('embed', '0')
    url.searchParams.set('logo', '0')
    url.searchParams.set('dark', '1')

    if (email) {
        url.searchParams.set('checkout[email]', email)
    }
    return url.toString()
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export function streamToJson<T = any>(
    mapper: (x: T extends ReadableStream<infer X> ? X : never) => any = (x) =>
        x,
) {
    const enc = new TextEncoder()
    return new TransformStream({
        transform(chunk, controller) {
            // console.log('chunk', chunk)
            controller.enqueue(
                enc.encode(JSON.stringify(mapper(chunk) || null) + '\n'),
            )
        },
    })
}

export function jsonToStream() {
    const dec = new TextDecoder()
    return new TransformStream({
        transform(chunk, controller) {
            const decoded = dec.decode(chunk)
            const parts = decoded.split('\n').filter(Boolean)
            for (let part of parts) {
                try {
                    controller.enqueue(JSON.parse(part) || null)
                } catch (e) {
                    throw new Error(
                        `Cannot parse json: ${JSON.stringify(part)}`,
                        { cause: e },
                    )
                }
            }
        },
    })
}

export function nDaysAgo(n) {
    let d = new Date()
    d.setDate(d.getDate() - n)
    return d
}

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}
