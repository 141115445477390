import Pricing from '@/components/framer/pricing'
import { useState } from 'react'
import { useStore } from '@nanostores/react'

import Nav from '@/components/framer/nav'
import {
    CogIcon,
    LogoutIcon,
    MoonIcon,
    SunIcon,
} from '@heroicons/react/outline'
import {
    Faq,
    Footer,
    Link,
    Modal,
    PageContainer,
    useColorMode,
    useColorModeValue,
    useThrowingFn,
} from 'beskar/landing'
import NextLink from 'next/link'

import { DropDownMenu } from 'beskar/src/DropDown'
import { AvatarButton } from 'beskar/src/Header'
import { signIn, signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

import { signupAtom } from '@/lib/state'
import classNames from 'classnames'

import { Button } from '@/components/ui/button'
import { env } from '@/env'
import { faviconUrl, ogImageUrl, useBuy } from '@/lib/hooks'
import splitbee from '@splitbee/web'
import { NextSeo } from 'next-seo'

export function MyNavbar({}) {
    const { data: session } = useSession()
    let canLogout = !!session
    return (
        <div className='flex flex-col items-center'>
            <Nav.Responsive
                signup={(e) => {
                    if (canLogout) {
                        signOut({ redirect: false })
                        return
                    }
                    signupAtom.set(true)
                }}
                logoLink='/'
                pricingLink='#pricing'
                textToSpeechLink='#speech'
                signupTitle={canLogout ? 'Sign out' : 'Sign up or Log In'}
                variants={{
                    base: 'Mobile',
                    lg: 'Desktop',
                    md: 'Tablet',
                }}
                className='!max-w-full !w-full'
            />
        </div>
    )
}

export function MyFooter({}) {
    const router = useRouter()
    return (
        <Footer
            justifyAround
            columns={{
                Company: (
                    <>
                        <button
                            onClick={async () => {
                                await signOut({ redirect: false })
                                router.push('/')
                            }}
                            className='apperance-none'
                        >
                            Sign Out
                        </button>
                    </>
                ),
                'Other projects': (
                    <>
                        <Link href='https://notaku.so'>Notaku</Link>
                    </>
                ),
                // 'Other languages': (
                //     <>
                //         <Link href='https://notaku.so'>Notaku</Link>
                //     </>
                // ),
                Contact: (
                    <>
                        <Link
                            href={`mailto:tommy@gesserit.co?subject=${encodeURIComponent('Gesserit.co Support')}`}
                        >
                            Support Via Email
                        </Link>
                        <Link href='/terms'>Terms of Service</Link>
                        <Link href='/privacy-policy'>Privacy Policy</Link>
                        {/* <Link href='mailto:tommy@salespack.io'>Contact me</Link> */}
                    </>
                ),
            }}
        />
    )
}

export function Logo({ className = '' }) {
    // const { status } = useSession()
    return (
        <NextLink legacyBehavior href={'/'} passHref>
            <a
                className={classNames(
                    'text-4xl space-x-3 items-center font-medium',
                    'flex',
                    className,
                )}
            >
                <LogoIcon className='h-[24px]' />
                <div className=''>Salespack</div>
            </a>
        </NextLink>
    )
}

function LogoIcon({ ...rest }) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={30}
            height={32}
            viewBox='0 0 30 32'
            {...rest}
        >
            <path
                fill='currentColor'
                d='M9.9,29.7840402 L0.93,13.9973735 C0.345,13.0221354 0,11.9402307 0,10.7364211 C0.00276767676,7.67006673 2.24801082,5.08341757 5.24237876,4.69689945 C8.2367467,4.31038133 11.0474327,6.24440255 11.805,9.21261161 L18,9.21261161 L18,5.4030878 C18,3.72689732 19.35,2.35546875 21,2.35546875 L21,6.30213542 L23.385,3.87927827 L30,3.87927827 L30,6.92689732 L24.615,6.92689732 L21,10.5992783 L21,10.873564 L24.615,14.5459449 L30,14.5459449 L30,17.593564 L23.385,17.593564 L21,15.1707068 L21,19.1173735 C19.3431458,19.1173735 18,17.752908 18,16.0697545 L18,12.2602307 L11.805,12.2602307 C11.655,12.8545164 11.415,13.4183259 11.115,13.9364211 L20.115,29.7840402 L27,29.7840402 C28.6568542,29.7840402 30,31.1485057 30,32.8316592 L30,34.3554688 L0,34.3554688 L0,32.8316592 C0,31.1554687 1.35,29.7840402 3,29.7840402 L9.9,29.7840402 Z M8.865,16.0697545 C8.025,16.5573735 7.05,16.8316592 6,16.8316592 L13.365,29.7840402 L16.65,29.7840402 L8.865,16.0697545 M6,7.68880208 C4.34314575,7.68880208 3,9.05326761 3,10.7364211 C3,12.4278497 4.335,13.7840402 6,13.7840402 C7.665,13.7840402 9,12.4278497 9,10.7364211 C9,9.05326761 7.65685425,7.68880208 6,7.68880208 Z'
                transform='translate(0 -2.355)'
            />
        </svg>
    )
}

export function LoginLink({}) {
    const { status } = useSession()
    const router = useRouter()
    const signupOpen = useStore(signupAtom)
    if (status === 'authenticated') {
        return
    }
    return (
        <div key={status} className='max-w-[14ch] text-left md:text-center'>
            <Link data-name='login' onClick={() => signupAtom.set(true)}>
                Login or Sign Up
            </Link>
        </div>
    )
}

export function MyPricing({}) {
    const { fn: createPortalClient, isLoading: isLoadingPortal } =
        useThrowingFn({
            fn: async () => {},
        })
    const router = useRouter()
    const buy = useBuy()
    return (
        <PageContainer>
            <div id='pricing' className='flex flex-col items-center'>
                <Pricing.Responsive
                    buyEvent={buy}
                    variants={{
                        lg: 'Desktop 1',
                        base: 'Mobile 1',
                    }}
                    className='!max-w-full'
                />
            </div>
        </PageContainer>
    )
}

export function MyFaq({ className = '' }) {
    return (
        <PageContainer
            className={classNames(
                'max-w-5xl px-4 w-full',
                'lg:px-0',
                className,
            )}
        >
            <div className='text-3xl font-semibold text-center'>
                Frequently asked questions
            </div>
            <Faq
                className='min-w-full text-gray-800'
                items={[
                    {
                        heading: 'Can i use Salespack for my agency?',
                        content: (
                            <div>
                                Yes! We have a members system that lets you
                                invite members to your org and let them monitor
                                the campaigns analytics and stats.
                                <br />
                                <br />
                                Usually we recommend that you create a separate
                                org for each client.
                            </div>
                        ),
                    },
                    {
                        heading: 'Do you have a free trial?',
                        content: (
                            <div>Yes! All plans have a 7 days free trial.</div>
                        ),
                    },
                    {
                        heading: 'What email accounts can i use?',
                        content: (
                            <div>
                                You can use any email accounts supporting SMTP
                                and IMAP (basically all providers, Google
                                requires some additional steps to enabled SMTP)
                                <br />
                                <br />
                                You can also create a new email account using
                            </div>
                        ),
                    },
                    {
                        heading: 'What is email warmup?',
                        content: (
                            <div className=''>
                                If you enable warmup for your email accounts we
                                will send emails from your account to other
                                Salespack users.
                                <br />
                                <br />
                                This lets you monitor if your emails end up in
                                spam and makes ISPs increase the trust scores of
                                your email accounts.
                                <br />
                                <br />
                                We will also move emails that end up in spam to
                                the main inbox.
                                <br />
                                <br />
                                All warmup emails you receive will be moved to a
                                separate folder to not clutter your inbox.
                            </div>
                        ),
                    },
                    {
                        heading: 'How are emails counted?',
                        content: (
                            <div className=''>
                                Every email you send in a campaign (counting
                                follow ups) increments your org emails count.
                                <br />
                                Warmup emails are not counted.
                            </div>
                        ),
                    },

                    {
                        heading: 'Who is behind Salespack?',
                        content: (
                            <span className=''>
                                I am Tommy, a software engineer living in Italy.
                                you can chat with me on{` `}
                                <Link
                                    underline
                                    href={'https://twitter.com/__morse'}
                                >
                                    Twitter
                                </Link>{' '}
                                :).
                            </span>
                        ),
                    },
                ]}
            />
        </PageContainer>
    )
}

export function SignupModal() {
    const signupOpen = useStore(signupAtom)
    const [isLoading, setIsLoading] = useState(false)

    const handleSignIn = async () => {
        setIsLoading(true)
        await signIn(
            'google',
            {
                callbackUrl: new URL('/', window.location.href).toString(),
            },
            { prompt: 'select_account' },
        )
        setTimeout(() => {
            setIsLoading(false)
        }, 5000)
    }

    return (
        <Modal
            useDefaultContentStyle
            className='md:!top-[300px]'
            maxWidth='800px'
            content={
                <div
                    className={classNames(
                        'py-16 flex flex-col items-center gap-8',
                    )}
                >
                    <Modal.CloseButton
                        onClick={() => {
                            signupAtom.set(false)
                        }}
                    />
                    <div className='text-3xl font-bold text-center'>
                        Sign up to continue
                    </div>
                    <div className='text-xl font-medium opacity-80 max-w-sm text-center'>
                        Sign up is required to prevent abuse
                    </div>
                    <Button
                        isLoading={isLoading}
                        onClick={handleSignIn}
                        disabled={isLoading}
                    >
                        {!isLoading ? <GoogleIcon /> : null}
                        Sign in with Google
                    </Button>
                </div>
            }
            isOpen={signupOpen}
            onClose={() => {
                signupAtom.set(false)
            }}
        ></Modal>
    )
}

function GoogleIcon() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={20}
            height={20}
            viewBox='0 0 48 48'
            className='mr-2'
        >
            <path
                fill='#4285F4'
                d='M24 9.5c3.1 0 5.6 1.1 7.5 2.9l5.6-5.6C33.4 3.5 28.9 1.5 24 1.5 14.8 1.5 7.3 7.9 4.7 16.1l6.9 5.4C13.1 15.1 18 9.5 24 9.5z'
            />
            <path
                fill='#34A853'
                d='M46.5 24c0-1.6-.1-3.1-.4-4.5H24v9h12.7c-.6 3.1-2.4 5.7-4.9 7.4l7.6 5.9c4.4-4.1 7.1-10.1 7.1-17.8z'
            />
            <path
                fill='#FBBC05'
                d='M10.6 28.5c-1.1-3.1-1.1-6.5 0-9.6L3.7 13.5C1.3 18.1 1.3 24 3.7 28.5l6.9-5.4z'
            />
            <path
                fill='#EA4335'
                d='M24 46.5c5.9 0 10.8-2 14.4-5.4l-7.6-5.9c-2.1 1.4-4.7 2.3-7.5 2.3-6 0-10.9-4.1-12.7-9.6l-6.9 5.4C7.3 40.1 14.8 46.5 24 46.5z'
            />
            <path fill='none' d='M0 0h48v48H0z' />
        </svg>
    )
}

export function MySEO({ title, description, canonicalPath = '' }) {
    return (
        <NextSeo
            {...{
                title,
                description,
                canonical: canonicalPath
                    ? new URL(`/`, env.NEXT_PUBLIC_URL).toString()
                    : undefined,
                openGraph: {
                    images: [{ url: ogImageUrl }],
                    description,
                    title,
                    siteName: 'Gesserit',
                },
                twitter: {
                    cardType: 'summary_large_image',
                },
                additionalLinkTags: [
                    {
                        rel: 'icon',
                        href: faviconUrl,
                    },
                    {
                        rel: 'apple-touch-icon',
                        href: faviconUrl,
                        // sizes: '76x76',
                    },
                ],
            }}
        />
    )
}
