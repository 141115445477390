import { useState } from 'react'
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from '@/components/ui/sheet'
import { Button } from '@/components/ui/button'
import { ChevronRight } from 'lucide-react'
import { VoiceTable } from './VoiceTable'

import { useVoiceStore } from '@/lib/state'
import { allVoices } from 'db/data'
import { ScrollArea } from '@/components/ui/scroll-area'

export function VoiceSelector() {
    const selectedVoice = useVoiceStore((state) =>
        allVoices.find(
            (voice) => voice?.uniqueId === state.selectedVoiceUniqueId,
        ),
    )
    const isOpen = useVoiceStore((state) => state.isVoiceSheetOpen)

    const setIsOpen = (open: boolean) => {
        useVoiceStore.setState({ isVoiceSheetOpen: open })
    }

    const selectText = selectedVoice
        ? `${selectedVoice.name}, ${selectedVoice.provider}`
        : 'Select Voice'
    return (
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild>
                <Button variant='outline'>
                    {selectText} <ChevronRight className='ml-2 h-4 w-4' />
                </Button>
            </SheetTrigger>
            <SheetContent className='p-0 max-h-full sm:max-w-[800px]'>
                <ScrollArea className='w-full h-full space-y-4'>
                    <VoiceTable />
                </ScrollArea>
            </SheetContent>
        </Sheet>
    )
}
