import { Button } from '@nextui-org/react'
import splitbee from '@splitbee/web'

import { createInviteLinkAuth } from '@/pages/api/invites'
import {
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    useDisclosure,
} from '@nextui-org/react'
import { motion } from 'framer-motion'

import { CopyButton } from 'beskar/src/dashboard'
import { useThrowingFn } from 'beskar/src/landing'

import { useSession } from 'next-auth/react'

import { createBuyLink } from '@/lib/utils'
import classNames from 'classnames'

import {
    getManageLemonSubscriptionUrl,
    getSubscriptions,
    getUserCredits,
    getUserOrg,
} from '@/pages/api/functions'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import useSWR from 'swr'

function onError(e: any) {
    toast.error(e.message)
}

export function SubscriptionInfo() {
    const { data: session } = useSession()
    const { data: credits, error } = useSWR('credits', () => getUserCredits(), {
        onError,
    })

    const progress = credits?.used / credits?.total

    if (!session || !progress) {
        return null
    }

    return (
        <div className='flex flex-col p-6  justify-center rounded-lg gap-6 max-w-md w-full items-center'>
            <div className='flex flex-col items-center gap-4'>
                <div className='text-center font-medium opacity-80'>
                    Used {credits.used} of {credits.total} words limit{' '}
                    {credits.free && '(free account)'}
                </div>
                <div className='flex items-center w-full gap-2'>
                    <ProgressBar
                        className='w-full text-xs'
                        progress={progress}
                    />
                    <div className='opacity-70 text-sm font-mono'>
                        {Number(Math.min(progress, 1) * 100).toFixed(0) + '%'}
                    </div>
                </div>

                <div className='mt-2 flex gap-4'>
                    <BuyButton ghost={progress < 0.5} />
                    <ManagePlan />
                </div>
            </div>
        </div>
    )
}

function BuyButton({ ghost = true }) {
    const { data: session } = useSession()
    let [href, setHref] = useState('')
    let [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        Promise.resolve().then(async () => {
            const org = await getUserOrg({})
            if (!org) {
                return
            }
            setHref(
                createBuyLink({
                    email: session?.user?.email,
                    orgId: org?.id,
                }),
            )
        })
    }, [])
    if (!href) {
        return null
    }

    return (
        <a
            href={href}
            className='lemonsqueezy-button font-bold text-sm'
            target=''
            onClick={() => {
                splitbee.track('buy intent')
                setIsLoading(true)
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000 * 5)
            }}
        >
            <Button
                isLoading={isLoading}
                disabled={!href}
            >
                Buy Credits
            </Button>
        </a>
    )
}

function ProgressBar({ progress, className = '' }) {
    const backgroundColor = (() => {
        if (progress > 0.9) {
            return 'bg-orange-500'
        }
        if (progress > 0.6) {
            return 'bg-yellow-400'
        }

        return 'bg-green-500'
    })()
    return (
        <div
            // style={{ backgroundColor }}
            className={classNames(
                'relative rounded-md overflow-hidden w-full bg-gray-700 flex h-[0.8em]',
                className,
            )}
        >
            <motion.div
                // layout
                transition={{ duration: 0.4 }}
                animate={{
                    width: Number(Math.min(progress, 1) * 100).toFixed(0) + '%',
                }}
                className={classNames(
                    'h-full bg-gray-200 rounded overflow-hidden',
                    backgroundColor,
                )}
            ></motion.div>
        </div>
    )
}

function ManagePlan({}) {
    const { data: subs } = useSWR('cancel plan', () =>
        getSubscriptions({ onError }),
    )
    const { fn: manage, isLoading } = useThrowingFn({
        fn: async () => {
            const { url } = await getManageLemonSubscriptionUrl({})
            if (!url) {
                toast.error(`No subscription found`)
                return
            }
            window.location.href = url
            // $crisp.push(['do', 'chat:send'])
        },
    })
    if (!subs) {
        return null
    }
    let sub = subs?.[0]
    if (!sub) {
        return null
    }
    return (
        <motion.div
            className='flex gap-3'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <Button isLoading={isLoading} className='text-sm' onClick={manage}>
                Manage Subscription
            </Button>
            {/* <InviteUser /> */}
        </motion.div>
    )
}

function InviteUser() {
    const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()
    const [url, setUrl] = useState('')
    const { fn: create, isLoading } = useThrowingFn({
        async fn() {
            const { url } = await createInviteLinkAuth({})
            setUrl(url)
            onOpen()
        },
    })
    return (
        <>
            <Button className='text-sm' isLoading={isLoading} onClick={create}>
                Invite Member
            </Button>
            <Modal backdrop='blur' isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    <>
                        <ModalHeader className='flex flex-col gap-1'>
                            Invite Member
                        </ModalHeader>

                        <ModalBody>
                            <p className=''>
                                Send the following link to invite members and
                                use your subscription
                            </p>
                            <Input
                                className='font-mono'
                                classNames={{ input: 'text-[13px]' }}
                                endContent={
                                    <CopyButton children='Copy' text={url} />
                                }
                                readOnly
                                value={url}
                            />
                        </ModalBody>
                        <ModalFooter>
                            {/* <Button color='primary'>Done</Button> */}
                        </ModalFooter>
                    </>
                </ModalContent>
            </Modal>
        </>
    )
}
