import AudioPlayerWithWebAudio from '@/components/AudioPlayerWithWebAudio'
import { useRouter } from 'next/router'
import { SubscriptionInfo } from '@/components/SubscriptionInfo'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { VoiceSelector } from '@/components/VoiceSelector'
import { toast } from '@/hooks/use-toast'
import { globalAudioPlayer } from '@/lib/AudioStreamPlayer'
import { signupAtom, useVoiceStore } from '@/lib/state'
import { generateVoiceStreamingWithAuth } from '@/pages/api/functions'
import { allVoices, VoiceData } from 'db/data'
import { useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'

import { uniqueLanguages, uniqueProviders } from 'db/data'

function SetDefaultFiltersAndLanguage() {
    const router = useRouter()
    const { pathname } = router
    const selectedLanguage = useVoiceStore((state) => state.selectedLanguage)
    const selectedProvider = useVoiceStore((state) => state.selectedProvider)
    const selectedVoice = useVoiceStore((state) =>
        allVoices.find((x) => x.uniqueId === state.selectedVoiceUniqueId),
    )

    useEffect(() => {
        if (selectedVoice) {
            return
        }

        let voices: VoiceData[] = allVoices
        const languageToUse =
            uniqueLanguages.find((lang) => pathname.includes(lang)) ||
            selectedLanguage
        const providerToUse =
            uniqueProviders.find((provider) => pathname.includes(provider)) ||
            selectedProvider

        if (providerToUse !== 'all') {
            voices = voices.filter((x) => x.provider === providerToUse)
        }
        if (languageToUse !== 'all') {
            voices = voices.filter((x) => x.language === languageToUse)
        }

        let voice = voices[0]

        if (!voice) {
            return
        }
        useVoiceStore.setState({
            selectedVoiceUniqueId: voice?.uniqueId,
            selectedLanguage: languageToUse,
            selectedProvider: providerToUse,
        })
    }, [pathname])
    return null
}

export default function TextToSpeechPrompt() {
    const text = useVoiceStore((state) => state.textToSpeechText)

    const selectedVoice = useVoiceStore((state) =>
        allVoices.find((x) => x.uniqueId === state.selectedVoiceUniqueId),
    )

    const [isLoading, setIsLoading] = useState(false)
    const canDownload = useVoiceStore((state) =>
        Boolean(!state.player.previewForVoiceId && state.player.duration),
    )
    const { data: session } = useSession()
    const handleGenerate = async () => {
        if (!session) {
            signupAtom.set(true)
            return
        }
        try {
            setIsLoading(true)
            globalAudioPlayer.reset()
            globalAudioPlayer.title = selectedVoice?.name
            globalAudioPlayer.description = text
            globalAudioPlayer.emit()
            const stream = await generateVoiceStreamingWithAuth({
                text,
                voiceId: selectedVoice?.id,
                provider: selectedVoice?.provider,
            })

            const audioUrls = []
            for await (const chunk of stream) {
                if (chunk?.type === 'error') {
                    toast({
                        title: chunk.error,
                        description: 'Could not generate audio',
                        variant: 'destructive',
                    })
                    return
                }
                const { audioUrl } = chunk
                // audioUrls.push(audioUrl);
                // const response = await fetch(audioUrl)
                // const audioBlob = await response.blob()
                await globalAudioPlayer.addChunk(audioUrl)
            }
            // Copy audio URLs to clipboard after completion
            // navigator.clipboard.writeText(JSON.stringify(audioUrls, null, 2));
        } finally {
            setIsLoading(false)
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
            handleGenerate()
        }
    }
    return (
        <>
            <div className='w-full max-w-2xl relative'>
                <Textarea
                    placeholder='Enter your text here...'
                    value={text}
                    onChange={(e) => {
                        useVoiceStore.setState({
                            textToSpeechText: e.target.value,
                        })
                        // Auto-grow the textarea
                        e.target.style.height = 'auto'
                        e.target.style.height = `${e.target.scrollHeight}px`
                    }}
                    onKeyDown={handleKeyDown}
                    className='min-h-[200px] max-h-[900px] !pb-[80px] rounded-2xl !text-base shadow-lg p-6'
                    style={{
                        resize: 'none',
                        overflow: 'hidden',
                    }}
                />

                <div className='absolute bottom-4 left-4 gap-4 right-4 flex justify-end'>
                    <div className=''>
                        <VoiceSelector />
                    </div>
                    <div className='grow'></div>
                    {canDownload && (
                        <Button
                            onClick={() => {
                                globalAudioPlayer.download()
                            }}
                            className='rounded-lg'
                            variant='ghost'
                        >
                            Download
                        </Button>
                    )}

                    <Button
                        onClick={handleGenerate}
                        className='rounded-lg'
                        isLoading={isLoading}
                        disabled={!selectedVoice || !text}
                    >
                        Generate
                    </Button>
                </div>
            </div>
            <AudioPlayerWithWebAudio />
            <SetDefaultFiltersAndLanguage />
            <SubscriptionInfo />
        </>
    )
}
