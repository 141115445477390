import { encodeMp3 } from '@/lib/mp3'

class AudioStreamPlayer {
    private audioContext: AudioContext | null = null
    private audioBuffers: AudioBuffer[] = []
    private currentSource: AudioBufferSourceNode | null = null
    private gainNode: GainNode | null = null
    private startTime: number = 0
    private pausedAt: number = 0
    private scheduledSources: {
        source: AudioBufferSourceNode
        startTime: number
        endTime: number
    }[] = []
    isPlaying: boolean = false
    playbackPromise: Promise<void> | null = null
    private playbackResolve: (() => void) | null = null

    title: string = ''
    description: string = ''
    currentTime: number = 0
    volume: number = 1

    private callbacks: (() => void)[] = []

    constructor() {
        if (typeof window !== 'undefined') {
            this.audioContext = new AudioContext()
            this.gainNode = this.audioContext.createGain()
            this.gainNode.connect(this.audioContext.destination)
            this.gainNode.gain.value = this.volume
        } else {
            // Polyfill for requestAnimationFrame in Node environment
            globalThis.requestAnimationFrame =
                globalThis.requestAnimationFrame ||
                function (callback) {
                    return setTimeout(callback, 1000 / 60)
                }
            try {
                this.audioContext = new AudioContext()
            } catch (e) {
                console.error(
                    'Could not create audio context, this machine probably does not have an output device',
                    e,
                )
                return
            }
            globalThis.AudioContext =
                globalThis.AudioContext ||
                require('node-web-audio-api').AudioContext
            globalThis.OfflineAudioContext =
                globalThis.OfflineAudioContext ||
                require('node-web-audio-api').OfflineAudioContext
            this.gainNode = this.audioContext.createGain()
            this.gainNode.connect(this.audioContext.destination)
            this.gainNode.gain.value = this.volume
        }
    }

    on(event: 'change', callback: () => void) {
        this.callbacks.push(callback)
    }

    off(event: 'change', callback: () => void) {
        this.callbacks = this.callbacks.filter((cb) => cb !== callback)
    }

    emit() {
        this.callbacks.forEach((callback) => callback())
    }

    async addChunk(audioUrl: string) {
        if (!this.audioContext) return

        const response = await fetch(audioUrl)
        if (!response.ok) {
            throw new Error(
                `Failed to fetch audio from ${audioUrl.slice(0, 200)} (status: ${response.status})`,
            )
        }
        const arrayBuffer = await response.arrayBuffer()
        const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer)
        this.audioBuffers.push(audioBuffer)

        if (!this.isPlaying) {
            this.play()
        } else {
            // Schedule the new buffer to play immediately after the last scheduled buffer
            const lastScheduled =
                this.scheduledSources[this.scheduledSources.length - 1]
            if (lastScheduled) {
                this.scheduleBuffer(audioBuffer, lastScheduled.endTime)
            }
        }
        this.emit()
    }

    private scheduleBuffer(buffer: AudioBuffer, startAt: number) {
        if (!this.audioContext || !this.gainNode) return

        const source = this.audioContext.createBufferSource()
        source.buffer = buffer
        source.connect(this.gainNode)

        const endTime = startAt + buffer.duration

        // Only start if it's in the future
        if (startAt > this.audioContext.currentTime) {
            source.start(Math.max(startAt, this.audioContext.currentTime))
        }

        this.scheduledSources.push({
            source,
            startTime: startAt,
            endTime,
        })

        return { source, endTime }
    }

    async play() {
        if (!this.audioContext || !this.gainNode || this.isPlaying) {
            return this.playbackPromise
        }

        this.playbackPromise = new Promise((resolve) => {
            this.playbackResolve = resolve
        })

        if (this.audioContext.state === 'suspended') {
            await this.audioContext.resume()
        }

        // Reset if at end
        const totalDuration = this.getDuration()
        if (this.currentTime >= totalDuration) {
            this.currentTime = 0
            this.pausedAt = 0
        }

        // Stop and clear any previously scheduled sources
        this.stopAllSources()

        // Find starting position
        let accumulatedDuration = 0
        let currentBufferIndex = 0
        let offsetInBuffer = 0

        for (let i = 0; i < this.audioBuffers.length; i++) {
            const duration = this.audioBuffers[i].duration
            if (accumulatedDuration + duration > this.currentTime) {
                currentBufferIndex = i
                offsetInBuffer = this.currentTime - accumulatedDuration
                break
            }
            accumulatedDuration += duration
        }

        // Schedule all buffers from current position
        const now = this.audioContext.currentTime
        let scheduleTime = now

        // Schedule first buffer with offset
        if (this.audioBuffers[currentBufferIndex]) {
            const source = this.audioContext.createBufferSource()
            source.buffer = this.audioBuffers[currentBufferIndex]
            source.connect(this.gainNode)
            source.start(now, offsetInBuffer)
            const endTime = now + (source.buffer.duration - offsetInBuffer)
            this.scheduledSources.push({ source, startTime: now, endTime })
            scheduleTime = endTime
        }

        // Schedule remaining buffers
        for (
            let i = currentBufferIndex + 1;
            i < this.audioBuffers.length;
            i++
        ) {
            this.scheduleBuffer(this.audioBuffers[i], scheduleTime)
            if (this.audioBuffers[i]) {
                scheduleTime += this.audioBuffers[i].duration
            }
        }

        this.startTime = now - this.currentTime
        this.isPlaying = true

        // Start time tracking
        const updateTime = () => {
            if (!this.isPlaying) return
            this.currentTime = this.audioContext!.currentTime - this.startTime

            // Check if we've reached the end
            if (this.currentTime >= this.getDuration()) {
                this.pause()
                this.currentTime = 0
                this.pausedAt = 0
            }

            this.emit()
            requestAnimationFrame(updateTime)
        }
        updateTime()

        this.emit()
        return this.playbackPromise
    }

    private stopAllSources() {
        this.scheduledSources.forEach(({ source }) => {
            try {
                source.stop()
            } catch (e) {
                // Ignore errors from already stopped sources
            }
        })
        this.scheduledSources = []
    }

    pause() {
        if (!this.audioContext || !this.isPlaying) return

        this.pausedAt = this.currentTime
        this.stopAllSources()
        this.isPlaying = false
        if (this.playbackResolve) {
            this.playbackResolve()
            this.playbackResolve = null
        }
        this.emit()
    }

    seek(position: number) {
        if (position < 0 || position > 1) {
            throw new Error('Position must be between 0 and 1')
        }
        const duration = this.getDuration()
        const seconds = position * duration
        this.seekSeconds(seconds)
    }
    async seekSeconds(seconds: number) {
        if (!this.audioContext) return

        this.stopAllSources()
        this.isPlaying = false

        this.currentTime = seconds
        this.startTime = this.audioContext.currentTime - seconds

        await this.play()
    }

    getDuration(): number {
        return this.audioBuffers.reduce(
            (total, buffer) => total + buffer.duration,
            0,
        )
    }

    updateVolume(volume: number) {
        this.volume = volume
        if (this.gainNode) {
            this.gainNode.gain.value = volume
        }
        this.emit()
    }

    async download(filename: string = 'audio.mp3') {
        if (!this.audioBuffers.length) return

        // Concatenate AudioBuffers using Web Audio API
        const totalLength = this.audioBuffers.reduce(
            (acc, buffer) => acc + buffer.length,
            0,
        )
        const sampleRate = this.audioBuffers[0].sampleRate
        const numberOfChannels = this.audioBuffers[0].numberOfChannels

        const offlineContext = new OfflineAudioContext(
            numberOfChannels,
            totalLength,
            sampleRate,
        )
        let offset = 0

        this.audioBuffers.forEach((buffer) => {
            const source = offlineContext.createBufferSource()
            source.buffer = buffer
            source.connect(offlineContext.destination)
            source.start(offset / sampleRate)
            offset += buffer.length
        })

        const renderedBuffer = await offlineContext.startRendering()
        const mergedBlob = await encodeMp3(renderedBuffer, { bitrate: 128 })

        const url = URL.createObjectURL(mergedBlob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()

        URL.revokeObjectURL(url)
    }
    reset() {
        this.pause()
        this.stopAllSources()
        this.audioBuffers = []
        this.currentTime = 0
        this.pausedAt = 0
        this.isPlaying = false
        this.volume = 1
        if (this.gainNode) {
            this.gainNode.gain.value = this.volume
        }
        if (this.playbackResolve) {
            this.playbackResolve = null
        }
        this.emit()
    }
}

export const globalAudioPlayer = new AudioStreamPlayer()
