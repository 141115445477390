import { hailuoVoices } from './hailuo-voices'
export const tikTokVoices = [
    // English US
    {
        id: 'en_us_001',
        name: 'Female',
        language: 'English',
        accent: 'American',
    },
    {
        id: 'en_us_006',
        name: 'Male 1',
        language: 'English',
        accent: 'American',
    },
    {
        id: 'en_us_007',
        name: 'Male 2',
        language: 'English',
        accent: 'American',
    },
    {
        id: 'en_us_009',
        name: 'Male 3',
        language: 'English',
        accent: 'American',
    },
    {
        id: 'en_us_010',
        name: 'Male 4',
        language: 'English',
        accent: 'American',
    },
    {
        id: 'en_male_narration',
        name: 'Male, Narrator',
        language: 'English',
        accent: 'American',
    },
    {
        id: 'en_male_funny',
        name: 'Male, Funny',
        language: 'English',
        accent: 'American',
    },
    {
        id: 'en_female_emotional',
        name: 'Female, Peaceful',
        language: 'English',
        accent: 'American',
    },
    {
        id: 'en_male_cody',
        name: 'Male, Serious',
        language: 'English',
        accent: 'American',
    },

    // English UK
    { id: 'en_uk_001', name: 'Male 1', language: 'English', accent: 'British' },
    { id: 'en_uk_003', name: 'Male 2', language: 'English', accent: 'British' },

    // Italian
    { id: 'it_male_m18', name: 'Male', language: 'Italian', accent: '' },

    // English AU
    {
        id: 'en_au_001',
        name: 'Female',
        language: 'English',
        accent: 'Australian',
    },
    {
        id: 'en_au_002',
        name: 'Male',
        language: 'English',
        accent: 'Australian',
    },

    // French
    { id: 'fr_001', name: 'Male 1', language: 'French', accent: '' },
    { id: 'fr_002', name: 'Male 2', language: 'French', accent: '' },

    // German
    { id: 'de_001', name: 'Female', language: 'German', accent: '' },
    { id: 'de_002', name: 'Male', language: 'German', accent: '' },

    // Spanish
    { id: 'es_002', name: 'Male', language: 'Spanish', accent: 'European' },
    { id: 'es_mx_002', name: 'Male', language: 'Spanish', accent: 'Mexican' },

    // Portuguese
    {
        id: 'br_001',
        name: 'Female 1',
        language: 'Portuguese',
        accent: 'Brazilian',
    },
    {
        id: 'br_003',
        name: 'Female 2',
        language: 'Portuguese',
        accent: 'Brazilian',
    },
    {
        id: 'br_004',
        name: 'Female 3',
        language: 'Portuguese',
        accent: 'Brazilian',
    },
    { id: 'br_005', name: 'Male', language: 'Portuguese', accent: 'Brazilian' },

    // Indonesian
    { id: 'id_001', name: 'Female', language: 'Indonesian', accent: '' },

    // Japanese
    { id: 'jp_001', name: 'Female 1', language: 'Japanese', accent: '' },
    { id: 'jp_003', name: 'Female 2', language: 'Japanese', accent: '' },
    { id: 'jp_005', name: 'Female 3', language: 'Japanese', accent: '' },
    { id: 'jp_006', name: 'Male', language: 'Japanese', accent: '' },

    // Korean
    { id: 'kr_002', name: 'Male 1', language: 'Korean', accent: '' },
    { id: 'kr_004', name: 'Male 2', language: 'Korean', accent: '' },
    { id: 'kr_003', name: 'Female', language: 'Korean', accent: '' },

    // Characters
    {
        id: 'en_us_ghostface',
        name: 'Ghostface',
        language: 'English',
        accent: 'Character',
    },
    {
        id: 'en_us_chewbacca',
        name: 'Chewbacca',
        language: 'English',
        accent: 'Character',
    },
    {
        id: 'en_us_c3po',
        name: 'C3PO',
        language: 'English',
        accent: 'Character',
    },
    {
        id: 'en_us_stitch',
        name: 'Stitch',
        language: 'English',
        accent: 'Character',
    },
    {
        id: 'en_us_stormtrooper',
        name: 'Stormtrooper',
        language: 'English',
        accent: 'Character',
    },
    {
        id: 'en_us_rocket',
        name: 'Rocket',
        language: 'English',
        accent: 'Character',
    },

    // Singing
    {
        id: 'en_female_f08_salut_damour',
        name: 'Alto',
        language: 'English',
        accent: 'Singing',
    },
    {
        id: 'en_male_m03_lobby',
        name: 'Tenor',
        language: 'English',
        accent: 'Singing',
    },
    {
        id: 'en_male_m03_sunshine_soon',
        name: 'Sunshine Soon',
        language: 'English',
        accent: 'Singing',
    },
    {
        id: 'en_female_f08_warmy_breeze',
        name: 'Warmy Breeze',
        language: 'English',
        accent: 'Singing',
    },
    {
        id: 'en_female_ht_f08_glorious',
        name: 'Glorious',
        language: 'English',
        accent: 'Singing',
    },
    {
        id: 'en_male_sing_funny_it_goes_up',
        name: 'It Goes Up',
        language: 'English',
        accent: 'Singing',
    },
    {
        id: 'en_male_m2_xhxs_m03_silly',
        name: 'Chipmunk',
        language: 'English',
        accent: 'Singing',
    },
    {
        id: 'en_female_ht_f08_wonderful_world',
        name: 'Dramatic',
        language: 'English',
        accent: 'Singing',
    },
] as const

export const allVoices = [
    ...hailuoVoices.map((voice) => ({
        ...voice,
        provider: 'minimax' as const,
        uniqueId: `minimax-${voice.id}`,
    })),
    ...tikTokVoices.map((voice) => ({
        ...voice,
        provider: 'tiktok' as const,
        uniqueId: `tiktok-${voice.id}`,
    })),
]

export type MinimaxVoiceId = (typeof hailuoVoices)[number]['id']

export type TTSLanguage = (typeof tikTokVoices)[number]['language']

export type VoiceData = (typeof allVoices)[number]

export const uniqueLanguagesSet = new Set(
    allVoices.map((voice) => voice['language']).filter(Boolean),
)

export const uniqueProvidersSet = new Set(
    allVoices.map((voice) => voice.provider).filter(Boolean),
)

export const uniqueLanguages = [...uniqueLanguagesSet]

export const uniqueProviders = [...uniqueProvidersSet]
