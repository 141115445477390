import { globalAudioPlayer } from '@/lib/AudioStreamPlayer'
import { allVoices } from 'db/data'
import { atom } from 'nanostores'
import { create } from 'zustand'

interface VoiceState {
    selectedVoiceUniqueId: string
    isVoiceSheetOpen: boolean
    selectedLanguage: string
    selectedProvider: string
    textToSpeechText: string
    setTextToSpeechText: (text: string) => void
    player: {
        isPlaying: boolean
        volume: number
        currentTime: number
        duration: number
        title: string
        description: string
        previewForVoiceId: string
    }
}

export const useVoiceStore = create<VoiceState>((set) => ({
    selectedVoiceUniqueId: '',
    isVoiceSheetOpen: false,
    selectedLanguage: 'all',
    selectedProvider: 'all',
    textToSpeechText: '',
    setTextToSpeechText: (text) => set({ textToSpeechText: text }),
    player: {
        isPlaying: false,
        volume: 1,
        currentTime: 0,
        duration: 0,
        title: '',
        description: '',
        previewForVoiceId: '',
    },
}))

if (typeof window !== 'undefined') {
    useVoiceStore.setState(
        JSON.parse(localStorage.getItem('voiceStoreState') || '{}'),
    )

    let debounceTimeout: NodeJS.Timeout | null = null

    let unsubscribe = useVoiceStore.subscribe((_x) => {
        let x = { ..._x }
        delete x.player
        delete x.isVoiceSheetOpen

        if (debounceTimeout) {
            clearTimeout(debounceTimeout)
        }

        debounceTimeout = setTimeout(() => {
            console.log('saving voiceStoreState', x)
            localStorage.setItem('voiceStoreState', JSON.stringify(x))
        }, 500)
    })
    // @ts-ignore
    if (import.meta.hot) {
        // @ts-ignore
        import.meta.hot.dispose(() => {
            if (unsubscribe) {
                unsubscribe()
            }
        })
    }
}

if (globalAudioPlayer) {
    const unsubscribe = globalAudioPlayer.on('change', () => {
        useVoiceStore.setState((x) => ({
            player: {
                ...x.player,
                currentTime: globalAudioPlayer.currentTime,
                duration: globalAudioPlayer.getDuration(),
                isPlaying: globalAudioPlayer.isPlaying,
                title: globalAudioPlayer.title,
                description: globalAudioPlayer.description,
                volume: globalAudioPlayer.volume,
            },
        }))
    })

    // @ts-ignore
    if (import.meta.hot) {
        // @ts-ignore
        import.meta.hot.dispose(() => {
            // @ts-ignore
            unsubscribe()
        })
    }
}

export let signupAtom = atom(false)
